<script>
import { issue } from "@/api/beijing28";
import { caizhong, lottery } from "@/api/home";
import {
  pc28ColorRule,
  pk10ColorRule,
  currentColorRule,
  lastDrawingFormat,
  lastDrawingRule,
} from "@/CONF.js";
import { getAnimals } from "@/api/touzhu.js";

export default {
  name: "GameList",
  components: {
    Header: () => import("components/base/Header"),
  },
  data() {
    return {
      games: null,
      header: {
        mainTitle: getWord("drawing_result"),
      },
      countdownTimer: null,
      gameCodenames: null,
      currentCount: 0,
      retriveCurrentDrawingRemainFrequency: 200,
      currentTitle: null,
      groupedGames: null,
      lastDrawingResults: null,

      currentColorRule,
      lastDrawingFormat,
      lastDrawingRule,

      imgUrlTitle: "//cpwap." + process.env.VUE_APP_PROD_DOMAIN + "/",
      cloudBaseFolder: this._TEMPLATE,

      zodiacRule: null,

      defaultBackgroundColor: {
        "template-1": "#ea5844",
        "template-2": "#ea5844",
        "template-3": "#FFCD3D",
      },
      defaultForegroundColor: {
        "template-1": "#ffffff",
        "template-2": "#ffffff",
        "template-3": "#000000",
      },
      defaultInitialNumber: {
        "template-1": "?",
        "template-2": "?",
        "template-3": "?",
      },
    };
  },
  props: [],
  inject: [],
  methods: {
    covertLastDrawing(type) {
      var number = {
        value: this.defaultInitialNumber[this._TEMPLATE],
        color: this.defaultForegroundColor[this._TEMPLATE],
        bgColor: this.defaultBackgroundColor[this._TEMPLATE],
        zodiac: null,
        name: undefined,
      };
      switch (type) {
        case "fastthreeone":
        case "fastthreethree":
        case "fastthreefive":
        case "fastthreeten":
        case "shfastthree":
        case "jsfastthree":
        case "hbfastthree":
        case "fc3d":
          return new Array(3).fill(number);
          break;
        case "bj28":
        case "jnd28":
        case "twbg28":
        case "mgelg28":
        case "jndx28":
        case "snfk28":
        case "pc28one":
          return new Array(4).fill(number);
          break;
        case "tencent":
        case "gdfiveselect":
        case "jxfiveselect":
        case "shfiveselect":
        case "sdfiveselect":
        case "jsfiveselect":
        case "shxfiveselect":
        case "anhfiveselect":
        case "gxklsf":
        case "jndxy":
        case "cqssc":
          return new Array(5).fill(number);
          break;
        case "lhc":
        case "lhcaomen":
        case "lhcone":
        case "lhcthree":
        case "lhcfive":
        case "lhcten":
          return new Array(7).fill(number);
          break;
        case "gdklten":
          return new Array(8).fill(number);
          break;
        case "bjsc":
        case "scone":
        case "scthree":
        case "scfive":
        case "xyft":
        case "azxy10":
          return new Array(10).fill(number);
          break;
        default:
          return new Array(3).fill(number);
          break;
      }
    },
    _drawingFormat(lastDrawing, type) {
      if (type.indexOf("twbg28") !== -1) {
        // console.log(lastDrawing);
        // debugger;
      }
      if (lastDrawing.id||lastDrawing.fgs_id) {
        if (this.zodiacRule && type.indexOf("lhc") === 0) {
          if (
            lastDrawing &&
            lastDrawing.num_one !== this.defaultInitialNumber[this._TEMPLATE]
          ) {
            return this.lastDrawingFormat(
              lastDrawing,
              this.lastDrawingRule,
              this.currentColorRule,
              this.zodiacRule,
              true,
              type
            );
          } else {
            return this.covertLastDrawing(type);
          }
        } else {
          if (
            this.lastDrawingFormat(
              lastDrawing,
              this.lastDrawingRule,
              this.currentColorRule,
              null,
              true,
              type
            ).length > 0
          ) {
            return this.lastDrawingFormat(
              lastDrawing,
              this.lastDrawingRule,
              this.currentColorRule,
              null,
              true,
              type
            );
          } else {
            return this.covertLastDrawing(type);
          }
        }
      } else {
        return this.covertLastDrawing(type);
      }
    
    },
    swtich(type) {
      if (type === "all") {
        this.currentTitle = "all";
        this.games.forEach((item) => {
          item.show = true;
        });
      }
      if (type === "high") {
        this.currentTitle = "high";
        this.games.forEach((item) => {
          item.show = true;
        });
        this.games
          .filter((item) => {
            if (item.frequency === "Low") {
              return true;
            }
          })
          .forEach((item) => {
            item.show = false;
          });
      }
      if (type === "low") {
        this.currentTitle = "low";
        this.games.forEach((item) => {
          item.show = true;
        });
        this.games
          .filter((item) => {
            if (item.frequency === "High") {
              return true;
            }
          })
          .forEach((item) => {
            item.show = false;
          });
      }
    },
    routerLink(item) {
      if (
        item.codename === "xyft" ||
        item.codename === "bjsc" ||
        item.codename === "cqssc" ||
        (item.codename.indexOf("28") > -1 && item.codename !== "pc28one")
      ) {
        this.$router.push({
          path: "beijing28/room_list",
          query: {
            type: item.codename,
            name: item.name,
          },
        });
      } else {
        this.$router.push({
          path: "/t",
          query: {
            type: item.codename,
            name: item.name,
          },
        });
      }
    },
    retriveCurrentDrawingRemain(gameType, _item) {
      var that = this;
      var currentItem = null;
      if (gameType instanceof Array) {
        currentItem = gameType[this.currentCount];
      } else {
        currentItem = gameType;
      }
      if (this.currentCount === gameType.length) return false;

      var currentObject = null;

      if (_item) {
        currentObject = this.games.filter((item) => {
          return item.codename === currentItem;
        })[0];
      } else {
        currentObject = this.games.filter((item, index) => {
          return this.currentCount === index;
        })[0];
      }

      return new Promise(function (resolve, reject) {
        // issue(currentItem).then((result) => {
        //   if (result.data.code === "SUCCESS") {
            if (
              that.$store.state.currentDrawing &&
              that.$store.state.currentDrawing[currentItem]
            ) {
              var result =
                that.$store.state.currentDrawing[currentItem].time_diff;
              resolve(result);
            } else {
              resolve(null);
            }
          // }
        // });
      }).then(function (result) {
        if (result !== null) {
          var item = currentObject;
          item.countdown = result;

          item.timer = setInterval(function () {
            if (item.countdown === 0) {
              if (item.timer !== null) {
                window.clearInterval(item.timer);
              }
              setTimeout(function () {
                that.retriveCurrentDrawingRemain(item.codename, item);
              }, 6000);
            } else {
              Object.assign(
                item.lastDrawingResult,
                that.$store.state.lastDrawing &&
                  that.$store.state.lastDrawing[currentItem]
              );
              --item.countdown;
            }
          }, 1000);
        }
        if (gameType instanceof Array) {
          that.currentCount++;

          that.countdownTimer = setTimeout(function () {
            that.retriveCurrentDrawingRemain(gameType);
          }, that.retriveCurrentDrawingRemainFrequency);
        } else {
          return false;
        }
      });
    },
    formatCountdownTimer(timeStamp) {
      if (isNaN(timeStamp)) {
        return "ERROR: invalid countdown format";
      } else {
        var hours = parseInt(timeStamp / 3600);
        var minutes = parseInt((timeStamp % 3600) / 60);
        var seconds = parseInt((timeStamp % 3600) % 60);
        return (
          (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
        );
      }
    },
    gettype(type, name, bypass) {
      if (this._TEMPLATE === "template-3" || bypass) {
        this.$router.push({
          path:
            "/pc28/DrawingHistory?type=" +
            type +
            "&name=" +
            name +
            "&navitype=true",
        });
      }
    },
    fetchGameIconPath(item) {
      return item.img;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$root.$emit("closeBottomTab", true);
    next();
  },
  mounted() {
    var that = this;
    this.$root.$emit("closeBottomTab", false);

    function groupBy(objectArray, property) {
      return objectArray.reduce(function (acc, obj) {
        var key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    }

    function getlastDrawingResult(type) {
      // debugger
      var related = that.lastDrawingResults.filter((item) => {
        return item.type === type;
      });
      if (related.length > 0) {
        return related[0].issue || {};
      }
    }

    caizhong().then((_result) => {
      if (_result.data.code == "SUCCESS") {
        lottery().then((result) => {
          if (result.data.code == "SUCCESS") {
            this.lastDrawingResults = result.data.result;
            //  debugger
            this.currentTitle = "all";
            var data = _result.data.result.numbers.children;

            var games = [];
            Object.keys(data).forEach((item) => {
              data[item].children.forEach((item) => {
                games.push(item);
              });
            });

            this.groupedGames = groupBy(games, "frequency");

            this.games = [];

            games.forEach((item, index) => {
              this.$set(
                this.games,
                index,
                Object.assign({}, item, {
                  codename: item.type,
                  countdown: null,
                  timer: null,
                  show: true,
                  lastDrawingResult: getlastDrawingResult(item.type),
                  img:
                    this.imgUrlTitle +
                    this.cloudBaseFolder +
                    "/home/game-list/" +
                    item.type +
                    ".png",
                })
              );
            });
            console.log(this.games);

            //data format done
            this.CustomScroll(this.$refs.gameList.id);

            var codenameList = [];
            this.games
              .reduce((acc, value) => acc.concat(value), [])
              .forEach((item) => {
                codenameList.push(item.codename);
              });
            this.gameCodenames = [...new Set(codenameList)];

            function run() {
              if (
                that.$store.state.currentDrawing &&
                that.$store.state.lastDrawing
              ) {
                that.retriveCurrentDrawingRemain(that.gameCodenames);
              } else {
                setTimeout(function () {
                  run();
                }, 1000);
              }
            }

            run();
          } else {
            app.Mint.Toast(result.data.msg);
          }
        });
      } else {
        app.Mint.Toast(_result.data.msg);
      }
    });

    getAnimals().then((result) => {
      if (result.data.code === "SUCCESS") {
        this.zodiacRule = result.data.result;
      }
    });
  },
  computed: {
    currentDrawing() {
      return (
        this.$store.state.currentDrawing &&
        this.$store.state.currentDrawing[this.gameType]
      );
    },
    lastDrawing() {
      return (
        this.$store.state.lastDrawing &&
        this.$store.state.lastDrawing[this.gameType]
      );
    },
  },
  watch: {},
  beforeDestroy() {
    this.games
      .reduce((acc, value) => acc.concat(value), [])
      .forEach((item) => {
        if (item.timer !== null) {
          window.clearInterval(item.timer);
          item.timer = null;
        }
      });
    if (this.countdownTimer !== null) {
      window.clearTimeout(this.countdownTimer);
    }
  },
};
</script>
<template>
  <section
    id="game-list"
    :class="_TEMPLATE"
  >
    <Header
      :mainTitle="header.mainTitle"
      :backHome=true
      :backButton=true
    />
    <div
      class="inner"
      ref="gameList"
      id="inner"
    >
      <ul class="block">
        <li
          v-for="(item,index) in games"
          v-if="item.show"
          :key="index"
          @click.stop="gettype(item.type,item.name)"
        >
          <span style="display:none">{{item}}</span>
          <img :src="fetchGameIconPath(item)" />
          <div class="right">
            <div class="row">
              <b>{{item.name}}</b>
            
              <h5 v-if="item.lastDrawingResult.issue">{{ getWord('number') }}<span>{{item.lastDrawingResult.issue}}</span>{{ getWord('period') }}</h5>
            </div>
            <div class="row">
              <ul
                class="last"
                :class="[item.type,{'kuai3':item.type.indexOf('fastthree')>-1}]"
              >
                <template v-if="item.type.indexOf('28')>-1">
                  <li
                    :style="{ 
                                        '--data-color':index===3&&(Number(_item.value)===13||Number(_item.value)===14)
                                            ?'#333333'
                                            : _item.value===defaultInitialNumber[_TEMPLATE]
                                                ?_item.bgColor
                                                :_item.color,
                                        '--data-bg-color':_item.bgColor 
                                    }"
                    v-for="(_item,index) in _drawingFormat(item.lastDrawingResult,item.type)"
                    :class="_item.name"
                    :key="index"
                  >
                    {{_item.value}}
                  </li>
                </template>
                <template v-else>
                  <li
                    :style="{  '--data-color':_item.value===defaultInitialNumber[_TEMPLATE]
                                            ?_item.bgColor
                                            :_item.color,
                                        '--data-bg-color':_item.bgColor 
                                    }"
                    v-for="(_item,index) in _drawingFormat(item.lastDrawingResult,item.type)"
                    :class="_item.name"
                    :zodiac="_item.zodiac||defaultInitialNumber[_TEMPLATE]"
                    :key="index"
                  >
                    <template v-if="item.type.indexOf('fastthree')>-1">
                      <span
                        v-if="_item.value!=='?'"
                        class="dice"
                        :class="'dice-'+_item.value"
                      ></span>
                      <span
                        v-else
                        class="dice"
                        style="padding: 0 0.5em;background:#ea5844"
                      >{{_item.value}}</span>
                    </template>
                    <template v-else>
                      {{_item.value}}
                    </template>
                  </li>
                </template>
                <li
                  class="sum"
                  v-if="item.type.indexOf('pc28one')>-1 && !isNaN(parseInt(item.lastDrawingResult.num_one))"
                >{{ item.lastDrawingResult.sum }}</li>
              </ul>
              <i
                v-if="item.lastDrawingResult.issue"
                class="history_list iconfont icon-back"
              ></i>
            </div>
            <div
              class="row btnlist"
               v-if="_TEMPLATE!=='template-3'"
            >
              <button @click="gettype(item.type,item.name,true)">{{ getWord('trend3') }}</button>
              <button @click="routerLink(item)">{{ getWord('go_betting') }}</button>
            </div>
            <div
              class="row"
            >
              <h6>
                <template v-if="item.lastDrawingResult.issue">{{ getWord(['until','number']) }}<span>{{Number(item.lastDrawingResult.issue)+1}}</span>{{ getWord(['period','handicap_open']) }}</template>
                <template v-else></template>
              </h6>
              <span :class="{number:item.countdown}">{{item.countdown?formatCountdownTimer(item.countdown):getWord('wait_for_handicap_renew')}}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>
<style lang='scss' scoped>
@import "@@/assets/style/_variables";
section#game-list {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  .history_list {
    display: inline-block;
    transform: rotateZ(180deg);
  }

  .inner {
    overflow: auto;

    ul {
      &.block {
        display: flex;
        flex-direction: column;

        > li {
          display: flex;
          box-sizing: border-box;
          overflow: hidden;

          .right {
            flex-grow: 1;

            .row {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .last {
              list-style: none;
              text-align: center;
              display: flex;
              width: 100%;

              li {
                display: inline-block;
                position: relative;
                color: var(--data-color);
                background-color: var(--data-bg-color);
                -webkit-text-stroke: 0.1px #333333;
              }
            }

            h5,
            h6 {
              font-weight: normal;
              white-space: nowrap;
            }

            .row {
              > span {
                display: block;

                &.dice {
                  display: inline-block;
                }
              }
            }

            span {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  &.template-1 {
    background-color: #f5f5f9;

    img {
      width: 0.8rem;
      height: 0.8rem;
      margin: 0.5rem 0.25rem;
    }

    .inner {
      ul {
        &.block {
          > li {
            background-color: #ffffff;
            margin-bottom: 0.15rem;

            .right {
              position: relative;

              .row {
                flex-direction: column;
                align-items: flex-start;

                &:first-child {
                  margin-top: 0.2rem;
                  padding: 0 0.25rem;
                }

                &:last-child,&.btnlist {
                  display: flex;
                  flex-direction: row;
                  padding: 0.25rem;
                  border-top: 1px solid #f5f5f9;

                  button {
                    color: $TEMPLATE2-theme-color;
                    border: 0;
                    background-color: transparent;
                  }
                }

                b {
                  font-size: 0.36rem;
                  font-weight: normal;
                }

                h5 {
                  font-size: 0.3rem;

                  span {
                    color: #ea5844;
                  }
                }

                .history_list {
                  position: absolute;
                  right: 0.25rem;
                  top: 0.25rem;
                  color: #bbbbbb;
                }

                .last {
                  display: flex;
                  margin: 0.2rem 0 0.25rem 0;

                  li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 0.5rem;
                    height: 0.5rem;
                    margin-right: 0.05rem;
                    border-radius: 100%;
                    color: #ffffff;
                  }

                  &.bj28,
                  &.jnd28,
                  &.twbg28,
                  &.mgelg28,
                  &.jndx28,
                  &.snfk28,
                  &.pc28one {
                    li {
                      background-color: var(--data-color);
                    }
                  }

                  &.gxklsf,
                  &.cqklten {
                    li {
                      background-color: var(--data-color);
                    }
                  }

                  &.lhc,
                  &.lhcaomen,
                  &.lhcone,
                  &.lhcthree,
                  &.lhcfive,
                  &.lhcten {
                    li {
                      background-color: var(--data-color);
                    }
                  }

                  &.shfastthree,
                  &.hbfastthree,
                  &.jsfastthree,
                  &.fastthreeone,
                  &.fastthreethree,
                  &.fastthreefive,
                  &.fastthreeten {
                    li {
                      margin: 0 0.1rem;
                      background-color: transparent;
                      box-shadow: none;

                      &:first-child {
                        margin-left: 0;
                      }

                      .dice {
                        transform: scale(2);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.template-2 {
    background-color: #f5f5f9;

    img {
      width: 0.8rem;
      height: 0.8rem;
      margin: 0.25rem;
    }

    .inner {
      ul {
        &.block {
          > li {
            background-color: #ffffff;
            margin-bottom: 0.15rem;

            .right {
              position: relative;

              .row {
                flex-direction: column;
                align-items: flex-start;

                &:first-child {
                  margin-top: 0.2rem;
                }

                &:last-child,&.btnlist {
                  display: flex;
                  flex-direction: row;
                  margin-left: -1.3rem;
                  padding: 0.25rem;
                  border-top: 1px solid #f5f5f9;

                  button {
                    color: $TEMPLATE2-theme-color;
                    border: 0;
                    background-color: transparent;
                  }
                }

                b {
                  font-size: 0.36rem;
                  font-weight: normal;
                }

                h5 {
                  font-size: 0.3rem;

                  span {
                    color: #ea5844;
                  }
                }

                .history_list {
                  position: absolute;
                  right: 0.25rem;
                  top: 0.25rem;
                  color: #bbbbbb;
                }

                .last {
                  display: flex;
                  margin: 0.2rem 0 0.25rem 0;

                  li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 0.5rem;
                    height: 0.5rem;
                    margin-right: 0.05rem;
                    border-radius: 100%;
                    color: #ffffff;
                  }

                  &.bj28,
                  &.jnd28,
                  &.twbg28,
                  &.mgelg28,
                  &.jndx28,
                  &.snfk28,
                  &.pc28one {
                    li {
                      background-color: var(--data-color);
                    }
                  }

                  &.gxklsf,
                  &.cqklten {
                    li {
                      background-color: var(--data-color);
                    }
                  }

                  &.lhc,
                  &.lhcaomen,
                  &.lhcone,
                  &.lhcthree,
                  &.lhcfive,
                  &.lhcten {
                    li {
                      background-color: var(--data-color);
                    }
                  }

                  &.shfastthree,
                  &.hbfastthree,
                  &.jsfastthree,
                  &.fastthreeone,
                  &.fastthreethree,
                  &.fastthreefive,
                  &.fastthreeten {
                    li {
                      margin: 0 0.1rem;
                      background-color: transparent;
                      box-shadow: none;

                      &:first-child {
                        margin-left: 0;
                      }

                      .dice {
                        transform: scale(2);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.template-3 {
    .history_list {
      color: #c8c8cd;
    }

    .inner {
      background-color: #f2f2f2;

      ul {
        &.block {
          background-color: #eeeeee;
          min-height: 2rem;
          margin-bottom: 0.1rem;

          > li {
            padding: 0.1rem 0.2rem;
            background-color: #ffffff;
            border-bottom: 1px solid #d9d9d9;

            &:nth-child(even) {
              background-color: #eeeeee;
            }

            .right {
              margin-left: 0.25rem;

              .row {
                margin: 0.1rem 0;
                height: 0.4rem;

                &:nth-child(2) {
                  height: 0.8rem;
                }

                &:last-child {
                  align-items: unset;
                }

                > ul {
                  &.kuai3 {
                    li {
                      &:first-child {
                        margin-left: 0;
                      }
                    }
                  }
                }
              }

              .last {
                &.bj28,
                &.jnd28,
                &.twbg28,
                &.mgelg28,
                &.jndx28,
                &.snfk28,
                &.pc28one,
                &.cqssc,
                &.azxy5,
                &.sscone,
                &.sscthree,
                &.sscfive,
                &.tencent,
                &.gdfiveselect,
                &.jsfiveselect,
                &.sdfiveselect,
                &.jxfiveselect,
                &.shhfiveselect,
                &.anhfiveselect,
                &.shxfiveselect,
                &.gxklsf,
                &.gdklten,
                &.cqklten,
                &.fc3d {
                  li {
                    color: #000000 !important;
                    width: 0.72rem;
                    height: 0.72rem;
                    line-height: 0.72rem;
                    border-radius: 100%;
                    margin-left: 0.175rem;
                    box-shadow: inset 0px 0px 4px 2px var(--data-color);
                    font-size: 0.36rem;
                    font-weight: bold;
                    margin-right: 0.1rem;

                    &:first-child {
                      margin-left: 0;
                    }
                  }
                }

                &.bj28,
                &.jnd28,
                &.twbg28,
                &.mgelg28,
                &.jndx28,
                &.snfk28,
                &.pc28one {
                  li {
                    &:before {
                      content: "+";
                      position: absolute;
                      right: 100%;
                      color: #333333;
                    }

                    &:first-child {
                      &:before {
                        content: "";
                      }
                    }

                    &:last-child {
                      &:before {
                        content: "=";
                      }
                    }
                  }
                }

                &.fc3d {
                  li {
                    background: transparent;
                    box-shadow: inset 0px 0px 4px 2px #ec2829;
                  }
                }

                &.bjsc,
                &.xyft,
                &.azxy10,
                &.scone,
                &.scthree,
                &.scfive {
                  li {
                    width: 0.46rem;
                    height: 0.46rem;
                    line-height: 0.46rem;
                  }
                }

                &.gdfiveselect,
                &.jsfiveselect,
                &.sdfiveselect,
                &.jxfiveselect,
                &.shhfiveselect,
                &.anhfiveselect,
                &.shxfiveselect {
                  li {
                    background: transparent;
                    box-shadow: inset 0px 0px 4px 2px var(--data-bg-color);
                  }
                }

                &.gxklsf,
                &.gdklten,
                &.cqklten {
                  li {
                    background-color: #ffffff;
                    box-shadow: inset 0px 0px 4px 2px #216fff;
                  }
                }

                &.gdklten,
                &.cqklten {
                  li {
                    width: 0.56rem;
                    height: 0.56rem;
                    line-height: 0.56rem;
                    box-shadow: inset 0px 0px 4px 1px #216fff;
                    margin-right: 0.1rem;
                    margin-left: 0;
                    font-size: 0.34rem;
                  }
                }

                &.cqssc,
                &.azxy5,
                &.sscone,
                &.sscthree,
                &.sscfive {
                  li {
                    font-weight: normal;
                    background-color: #ffffff;
                    box-shadow: inset 0px 0px 4px 2px #ffcd3d;
                  }
                }

                &.tencent {
                  li {
                    background-color: #ffffff;
                    box-shadow: inset 0px 0px 4px 2px #216fff;
                  }
                }

                &.shfastthree,
                &.hbfastthree,
                &.jsfastthree,
                &.fastthreeone,
                &.fastthreethree,
                &.fastthreefive,
                &.fastthreeten {
                  li {
                    margin: 0.1rem 0.25rem;
                    background-color: transparent;
                    box-shadow: none;

                    &:first-child {
                      margin-left: 0.25rem;
                    }

                    .dice {
                      transform: scale(2);
                    }
                  }
                }

                &.lhc,
                &.lhcaomen,
                &.lhcone,
                &.lhcthree,
                &.lhcfive,
                &.lhcten {
                  + i {
                    margin-top: -0.2rem;
                  }

                  li {
                    margin: 0 0.05rem;
                    width: 0.58rem;
                    height: auto;
                    background-color: transparent;
                    background-size: contain;
                    background-repeat: no-repeat;
                    font-size: 0.28rem;
                    box-shadow: none;
                    font-weight: normal;
                    background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-grey.png");
                    background-position-y: -0.05rem;

                    &:last-child {
                      margin-left: 0.25rem;
                    }

                    &:after {
                      content: attr(zodiac);
                      font-size: 0.24rem;
                      color: #333333;
                      height: 0.24rem;
                      line-height: 0.24rem;
                      margin-top: 2px;
                      display: block;
                    }

                    &.red {
                      background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-red.png");
                    }

                    &.blue {
                      background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-blue.png");
                    }

                    &.green {
                      background-image: url("/static/assets/template-3/images/tradingfloor/icons/lhc-number-green.png");
                    }
                  }
                }

                li {
                  font-weight: 300;
                  box-shadow: 0px 0px 3px 0px rgba(57, 133, 225, 0.5);
                  border-radius: 3px;
                  font-size: 0.3rem;
                  width: 0.48rem;
                  height: 0.48rem;
                  line-height: 0.48rem;
                  margin: 0 1.5px;

                  &:first-child {
                    margin-left: 0;
                  }

                  &.sum {
                    box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.4);
                    background-color: #ffffff;
                  }
                }
              }

              b {
                font-size: 0.3rem;
                color: #5f646e;
              }

              h5,
              h6 {
                font-size: 0.3rem;

                span {
                  color: #ec2829;
                }
              }

              .row {
                > span {
                  font-size: 0.3rem;
                  color: #41a441;

                  &.dice {
                    display: inline-block;
                  }

                  &.number {
                    color: #5f646e;
                  }
                }
              }
            }

            img {
              width: 1rem;
              height: 1rem;
              flex-basis: 1rem;
              margin-top: 0.15rem;
            }
          }
        }
      }
    }
  }
}
</style>