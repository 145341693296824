var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:_vm._TEMPLATE,attrs:{"id":"game-list"}},[_c('Header',{attrs:{"mainTitle":_vm.header.mainTitle,"backHome":true,"backButton":true}}),_c('div',{ref:"gameList",staticClass:"inner",attrs:{"id":"inner"}},[_c('ul',{staticClass:"block"},_vm._l((_vm.games),function(item,index){return (item.show)?_c('li',{key:index,on:{"click":function($event){$event.stopPropagation();return _vm.gettype(item.type,item.name)}}},[_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(item))]),_c('img',{attrs:{"src":_vm.fetchGameIconPath(item)}}),_c('div',{staticClass:"right"},[_c('div',{staticClass:"row"},[_c('b',[_vm._v(_vm._s(item.name))]),(item.lastDrawingResult.issue)?_c('h5',[_vm._v(_vm._s(_vm.getWord('number'))),_c('span',[_vm._v(_vm._s(item.lastDrawingResult.issue))]),_vm._v(_vm._s(_vm.getWord('period')))]):_vm._e()]),_c('div',{staticClass:"row"},[_c('ul',{staticClass:"last",class:[item.type,{'kuai3':item.type.indexOf('fastthree')>-1}]},[(item.type.indexOf('28')>-1)?_vm._l((_vm._drawingFormat(item.lastDrawingResult,item.type)),function(_item,index){return _c('li',{key:index,class:_item.name,style:({ 
                                      '--data-color':index===3&&(Number(_item.value)===13||Number(_item.value)===14)
                                          ?'#333333'
                                          : _item.value===_vm.defaultInitialNumber[_vm._TEMPLATE]
                                              ?_item.bgColor
                                              :_item.color,
                                      '--data-bg-color':_item.bgColor 
                                  })},[_vm._v(" "+_vm._s(_item.value)+" ")])}):_vm._l((_vm._drawingFormat(item.lastDrawingResult,item.type)),function(_item,index){return _c('li',{key:index,class:_item.name,style:({  '--data-color':_item.value===_vm.defaultInitialNumber[_vm._TEMPLATE]
                                          ?_item.bgColor
                                          :_item.color,
                                      '--data-bg-color':_item.bgColor 
                                  }),attrs:{"zodiac":_item.zodiac||_vm.defaultInitialNumber[_vm._TEMPLATE]}},[(item.type.indexOf('fastthree')>-1)?[(_item.value!=='?')?_c('span',{staticClass:"dice",class:'dice-'+_item.value}):_c('span',{staticClass:"dice",staticStyle:{"padding":"0 0.5em","background":"#ea5844"}},[_vm._v(_vm._s(_item.value))])]:[_vm._v(" "+_vm._s(_item.value)+" ")]],2)}),(item.type.indexOf('pc28one')>-1 && !isNaN(parseInt(item.lastDrawingResult.num_one)))?_c('li',{staticClass:"sum"},[_vm._v(_vm._s(item.lastDrawingResult.sum))]):_vm._e()],2),(item.lastDrawingResult.issue)?_c('i',{staticClass:"history_list iconfont icon-back"}):_vm._e()]),(_vm._TEMPLATE!=='template-3')?_c('div',{staticClass:"row btnlist"},[_c('button',{on:{"click":function($event){return _vm.gettype(item.type,item.name,true)}}},[_vm._v(_vm._s(_vm.getWord('trend3')))]),_c('button',{on:{"click":function($event){return _vm.routerLink(item)}}},[_vm._v(_vm._s(_vm.getWord('go_betting')))])]):_vm._e(),_c('div',{staticClass:"row"},[_c('h6',[(item.lastDrawingResult.issue)?[_vm._v(_vm._s(_vm.getWord(['until','number']))),_c('span',[_vm._v(_vm._s(Number(item.lastDrawingResult.issue)+1))]),_vm._v(_vm._s(_vm.getWord(['period','handicap_open'])))]:void 0],2),_c('span',{class:{number:item.countdown}},[_vm._v(_vm._s(item.countdown?_vm.formatCountdownTimer(item.countdown):_vm.getWord('wait_for_handicap_renew')))])])])]):_vm._e()}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }